import { ApiService, NormalizeResponse, SERVICES } from "../../BaseService";
import _ from "lodash";

export const ProductService = {
    getAll: () => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`products`));
    },
    getById: (productId: number) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`products/${productId}`));
    },
    getGroupById: (groupId: number) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`products-groups/${groupId}`));
    },
    getRubrosGroups: () => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`products/groups/rubros`));
    },
    getGrains: () => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`products/groups/grains`));
    },
    getProductsByGroupId: (productGroupId: number) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`products-groups/${productGroupId}`));
    },
    getProductsByGroupsIds: (productGroupIds: number[]) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`products-groups/multiple?${_.map(productGroupIds, (id) => `product_group_ids=${id}`).join("&")}`));
    },
    getActualsPrices: (periodId: number, productIds: number[], targetAgentIds: number[]) => {
        return NormalizeResponse(
            ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(
                `forecast/actuals?period_id=11&${_.map(productIds, (id) => `product_ids=${id}`).join("&")}&${_.map(targetAgentIds, (id) => `target_agent_ids=${id}`).join("&")}`
            )
        );
    },
};
